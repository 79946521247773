import { h } from 'preact'
import { useState } from 'preact/hooks'
import SingleChart from './single-chart'
import { BankData } from 'models/market'
import { formatNumber, getRatePrecision } from 'utils/format-number'
import { i18n } from 'i18n/i18n'

interface BankOptionsElementProps {
  banksData: BankData[]
  buyCurrency: string
  sellCurrency: string
}

const BankOptionsElement = ({ banksData, buyCurrency, sellCurrency }: BankOptionsElementProps) => {
  const worstRateBank = banksData[banksData.length - 1].bank_id
  const [selectedBank, setSelectedBank] = useState<string>(worstRateBank)
  const selectedBankData = banksData.find((bank) => bank.bank_id === selectedBank)
  const setClickedBank = (clickedBank: string): void => {
    const clickedBankId = banksData.find((bank) => bank.bank_id === clickedBank)
    if (clickedBankId) setSelectedBank(clickedBankId.bank_id)
  }
  const currencyPair = `${buyCurrency}_${sellCurrency}`

  return (
    <div className="calculator-chart__single-chart-row">
      <div className="calculator-chart__single-cell is-wide is-space-unset">
        {selectedBankData && (
          <SingleChart
            isBankSelect={true}
            banksData={banksData}
            bankName={selectedBankData.bank_display_name}
            offerPercentage={selectedBankData.offer_percent}
            handleSetClickedBank={setClickedBank}
          />
        )}
      </div>
      <div className="calculator-chart__single-cell">
        <div className="calculator-chart__row-value visible-on-mobile">{i18n('calculator.rateText')}</div>
        <div className="calculator-chart__row-value">
          {formatNumber(selectedBankData.rate, getRatePrecision(currencyPair))}
        </div>
      </div>
      <div className="calculator-chart__single-cell">
        <div className="calculator-chart__row-value visible-on-mobile">{i18n('calculator.amountText')}</div>
        <div className="calculator-chart__row-value">
          {formatNumber(selectedBankData.amount, 2)} {sellCurrency}{' '}
        </div>
      </div>
    </div>
  )
}

export default BankOptionsElement
