import cookieService from 'services/cookie-service'
import { Language } from 'models/cookies'

export enum SupportedLanguages {
  pl = 'pl',
  en = 'en',
  ru = 'ru',
  uk = 'uk',
  de = 'de',
  sk = 'sk',
  it = 'it',
  ro = 'ro',
  cs = 'cs',
  es = 'es',
  fr = 'fr',
}

export const getLangFromCookie = (): string | null => {
  const cookieLang = cookieService.getItem('language')
  return SupportedLanguages[cookieLang] ? cookieLang : null
}

export const getLangFromUrl = (pathname?: string): Language => {
  const url = pathname || window.location.pathname
  let lang: Language = 'pl'

  Object.values(SupportedLanguages).forEach((supportedLang) => {
    if (url.includes(`/${supportedLang}/`) || url === `/${supportedLang}`) {
      lang = supportedLang as Language
    }
  })
  return lang
}

export const setCookieLangFromUrl = (): void => {
  const urlLang = String(getLangFromUrl()).toLowerCase()
  cookieService.setItem('language', urlLang, cookieService.maximalCookieTTL)
}

const currentLanguage =
  typeof window !== 'undefined' ? process?.env?.LANGUAGE || getLangFromUrl() || SupportedLanguages.pl : ''

export const getCurrentLanguage = (): string => currentLanguage
