/**
 * Formats a number or numeric string to a locale-specific string with a specified number of fraction digits.
 *
 * @param num - The number or numeric string to format.
 * @param fractionDigits - The number of digits to appear after the decimal point.
 * @returns The formatted number as a string.
 */
export function formatNumber(num: number | string, fractionDigits: number): string {
  return parseFloat(num.toString()).toLocaleString('pl-PL', {
    minimumFractionDigits: fractionDigits,
    maximumFractionDigits: fractionDigits,
  })
}

/**
 * Formats a number with a thousands separator.
 *
 * This function takes a number or a string representing a number and formats it
 * to include a non-breaking space as the thousands separator.
 *
 * @param num - The number or string to format.
 * @returns The formatted number as a string with a non-breaking space as the thousands separator.
 */
export function formatNumberWithSep(num: number | string): string {
  return formatNumber(num, 2).replace(/\B(?=(\d{3})+(?!\d))/g, '\u00A0')
}

/**
 * Converts a locale-specific string representation of a number to a float-compatible string.
 * This function trims any whitespace from the input string and replaces commas with periods.
 *
 * @param value - The locale-specific string representation of a number.
 * @returns The float-compatible string representation of the number.
 */
export function localeStringToFloat(value: string): string {
  return value.trim().replace(/,/g, '.')
}

/**
 * Determines the precision of the rate for a given currency pair.
 *
 * @param pair - The currency pair in the format 'XXX_YYY'.
 * @returns The precision of the rate: 6 for long rate pairs, 4 for others.
 * @throws Will throw an error if the currency pair format is invalid.
 */
export function getRatePrecision(pair: string): number {
  if (typeof pair !== 'string' || !pair.includes('_')) {
    throw new Error('Invalid currency pair format')
  }
  const longRatePairs = ['HUF_PLN', 'JPY_PLN', 'PLN_HUF', 'PLN_JPY']
  const isLongRate = longRatePairs.includes(pair)
  return isLongRate ? 6 : 4
}
