import { AllowedParam, CookiesArray } from 'models/cookies'
import { SupportedLanguages } from 'utils/current-language'

export const isPanelUrl = (url: URL): boolean =>
  url.hostname.includes('user.') || url.hostname.includes('panel.') || url.hostname.includes('biz.')

export const doesUrlContainParam = (key: string, urlParamsObject?: object): boolean => {
  urlParamsObject = urlParamsObject || getParamsFromUrl()
  return !!urlParamsObject[key]
}

export const getParamsFromUrl = (): object => {
  const urlParams = new URLSearchParams(window.location.search)
  return Array.from(urlParams.keys()).length > 0 ? Object.fromEntries(urlParams.entries()) : {}
}

export const removeUrlParams = (url: string, paramsToRemove: string[]): string => {
  const urlObj = new URL(url)
  const searchParams = urlObj.searchParams

  paramsToRemove.forEach((param) => searchParams.delete(param))

  return urlObj.origin + urlObj.pathname + (searchParams.toString() ? `?${searchParams.toString()}` : '') + urlObj.hash
}

export const decodeUrlParams = (url: string): { [key: string]: any } => {
  try {
    const { search, hash } = new URL(url)
    const searchParams = Object.fromEntries(new URLSearchParams(search).entries())
    const paramsString = hash.startsWith('#/?') ? hash.slice(2) : ''
    const encodedParams = paramsString ? new URLSearchParams(paramsString).get('data') : null
    let decodedParams = {}

    if (encodedParams) {
      decodedParams = JSON.parse(decodeURIComponent(encodedParams))
      decodedParams = Array.isArray(decodedParams) ? Object.assign({}, ...decodedParams) : decodedParams
    }
    return { ...searchParams, ...decodedParams }
  } catch (error) {
    console.error('Error decoding URL params:', error)
    return {}
  }
}

export const createUrlWithParams = (cookiesToParam: CookiesArray, url: URL): string => {
  if (isPanelUrl(url)) {
    url.searchParams.delete('lang')
  }
  const separator = url.href.includes('?') ? '&' : '?'
  const params = new URLSearchParams(Object.entries(cookiesToParam).map(([key, value]) => [key, String(value)]))
  if (isPanelUrl(url)) {
    let urlWithParams = ''
    if (params.has('language')) {
      urlWithParams = url.href.includes('#/')
        ? url.href.replace('#/', `${separator}lang=${params.get('language')}#/`)
        : `${url.href}${separator}lang=${params.get('language')}#/`
      params.delete('language')
      return params.toString() ? `${urlWithParams}?${params.toString()}` : urlWithParams
    }
    urlWithParams = url.href.includes('#/') ? url.href : `${url.href}#/`
    return params.toString() ? `${urlWithParams}?${params.toString()}` : urlWithParams
  }

  url.search = params.toString()
  return url.toString()
}

export function validateParam(param: AllowedParam, value: any): boolean {
  if (param.type === 'boolean') {
    try {
      return JSON.parse(value) === true || JSON.parse(value) === false
    } catch (e) {
      return false
    }
  }
  if (param.type === 'string') {
    if (typeof value !== 'string' || value === 'true' || value === 'false') {
      return false
    }

    if (param.name === 'lang' && param.allowedValues) {
      const allowedLanguages = Object.values(SupportedLanguages)
      return allowedLanguages.includes(value as SupportedLanguages)
    }

    return true
  }
  return false
}
