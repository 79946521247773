import { h } from 'preact'
import { StateUpdater, useState, useEffect } from 'preact/hooks'
import { CalculatorDirection } from 'models/apps'
import SingleOption from './single-option'
import { i18n } from 'i18n/i18n'

interface CalculatorDirectionSelectProps {
  direction: CalculatorDirection
  handleSetDirection: StateUpdater<CalculatorDirection>
}

const FIXED_OPTION_LIST: CalculatorDirection[] = ['BUY', 'SELL']

const CalculatorDirectionSelect = ({ direction, handleSetDirection }: CalculatorDirectionSelectProps) => {
  const [isSelectVisible, setSelectVisibility] = useState<boolean>(false)

  const changeSelectVisibiliy = (): void => {
    setSelectVisibility(!isSelectVisible)
  }

  useEffect(() => {
    document
      .querySelector<HTMLDivElement>('.hero-form__main-section')
      .addEventListener('click', (e) => disableOptionsList(e))
  })

  const disableOptionsList = (e) => {
    const target = e?.target as HTMLElement
    const $summaryElement = document.querySelector<HTMLDivElement>('[data-calculator-direction]')
    if (e.target !== $summaryElement && isSelectVisible === true && !target.closest('[data-direction-holder]')) {
      setSelectVisibility(!isSelectVisible)
    }
  }

  const setSelectedOption = (option: CalculatorDirection): void => {
    handleSetDirection(option)
  }

  const handleSetClickedOption = (selectedOption: string): void => {
    const translatedOption = selectedOption === `${i18n('calculator.buySelect')}` ? 'BUY' : 'SELL'
    setSelectedOption(translatedOption)
  }

  const translateOption = (direction: CalculatorDirection): string => {
    return direction === 'BUY' ? `${i18n('calculator.buySelect')}` : `${i18n('calculator.sellSelect')}`
  }

  return (
    <div
      className="hero-form__options-holder"
      data-direction-holder
    >
      <div
        className="hero-form__select is-width"
        onClick={changeSelectVisibiliy}
        data-value={translateOption(direction)}
        data-calculator-direction
      >
        <p>{translateOption(direction)}</p>
        <span className="hero-form__select-chevron" />
      </div>
      {isSelectVisible && (
        <div
          className="hero-form__options-list is-width"
          onClick={changeSelectVisibiliy}
        >
          {FIXED_OPTION_LIST.map((option) => (
            <SingleOption
              key={option}
              handleSetClickedOption={handleSetClickedOption}
              singleOption={translateOption(option)}
            />
          ))}
        </div>
      )}
    </div>
  )
}

export default CalculatorDirectionSelect
