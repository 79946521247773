import { promiseWrapper } from 'utils/promise'
import { SupportedCurrencyPairsPayload, DataForSavingsCalculator } from '../models/endpoints-payload'

declare const USER_BASE_URL_WT: string

export const fetchSupportedCurrencyPairs = (): Promise<SupportedCurrencyPairsPayload> => {
  return promiseWrapper<SupportedCurrencyPairsPayload, null>(
    `${USER_BASE_URL_WT}/api/public/settings?keys[]=market-currency-pairs`,
    'GET',
  )
}

export const fetchDataForSavingsCalculator = (): Promise<DataForSavingsCalculator> => {
  return promiseWrapper<DataForSavingsCalculator, null>(
    `${USER_BASE_URL_WT}/api/public/settings?keys[]=market-currency-pairs&keys[]=currency`,
    'GET',
  )
}
