import { h } from 'preact'
import { StateUpdater, useState, useEffect } from 'preact/hooks'
import SingleOption from './single-option'

interface CalculatorCurrencySelectProps {
  isBuyComponent: boolean
  buyCurrency: string
  sellCurrency: string
  handleSetBuyCurrency: StateUpdater<string>
  handleSetSellCurrency: StateUpdater<string>
  fixedCurrencyList: string[]
}

const CalculatorCurrencySelect = ({
  isBuyComponent,
  buyCurrency,
  sellCurrency,
  handleSetBuyCurrency,
  handleSetSellCurrency,
  fixedCurrencyList,
}: CalculatorCurrencySelectProps) => {
  const [isSelectVisible, setSelectVisibility] = useState<boolean>(false)

  const changeSelectVisibiliy = (): void => {
    setSelectVisibility(!isSelectVisible)
  }

  useEffect(() => {
    document
      .querySelector<HTMLDivElement>('.hero-form__main-section')
      .addEventListener('click', (e) => disableOptionsList(e))
  })

  const selectedCurrencyID = isBuyComponent ? buyCurrency : sellCurrency
  const selectDirection = isBuyComponent ? 'buy' : 'sell'

  const disableOptionsList = (e) => {
    const target = e?.target as HTMLElement
    const $summaryElement = document.querySelector<HTMLDivElement>(`[data-currency-holder=${selectDirection}]`)
    if (
      e.target !== $summaryElement &&
      isSelectVisible === true &&
      !target.closest(`[data-calculator-currency=${selectDirection}]`)
    ) {
      setSelectVisibility(!isSelectVisible)
    }
  }

  const setSelectedCurrency = (selectedOption: string): void => {
    if (isBuyComponent) {
      handleSetBuyCurrency(selectedOption)
      selectedOption === sellCurrency ? handleSetSellCurrency(buyCurrency) : ''
    } else {
      handleSetSellCurrency(selectedOption)
      selectedOption === buyCurrency ? handleSetBuyCurrency(sellCurrency) : ''
    }
  }

  return (
    <div
      className="hero-form__options-holder"
      data-currency-holder={selectDirection}
    >
      <div
        className="hero-form__select"
        onClick={changeSelectVisibiliy}
        data-calculator-currency={selectDirection}
        data-value={selectedCurrencyID}
      >
        <p>{selectedCurrencyID}</p>
        <span className="hero-form__select-chevron" />
      </div>
      {isSelectVisible && (
        <div
          className="hero-form__options-list"
          onClick={changeSelectVisibiliy}
        >
          {fixedCurrencyList.map((option) => (
            <SingleOption
              key={option}
              handleSetClickedOption={setSelectedCurrency}
              singleOption={option}
            />
          ))}
        </div>
      )}
    </div>
  )
}

export default CalculatorCurrencySelect
