import { h } from 'preact'
import { useEffect } from 'preact/hooks'
import SingleChart from './single-chart'
import { BankData } from 'models/market'
import { i18n } from 'i18n/i18n'
import { dialogHandle } from 'utils/dialog'
import { formatNumber, getRatePrecision } from 'utils/format-number'

interface SingleBankRowProps {
  bankData: BankData
  buyCurrency: string
  sellCurrency: string
  banksData?: BankData[]
  isFirstRow?: boolean
}

const SingleBankRow = ({ bankData, buyCurrency, sellCurrency, banksData, isFirstRow }: SingleBankRowProps) => {
  const addWtClass = isFirstRow ? 'is-wt' : ''
  const currencyPair = `${buyCurrency}_${sellCurrency}`

  useEffect(() => {
    const $dialogOpener = document.querySelector<HTMLElement>('.calculator-chart__main-chart-holder [data-open-dialog]')
    const $dialog = document.querySelector<HTMLDialogElement>('.calculator-chart__main-chart-holder [data-dialog]')
    dialogHandle($dialog, $dialogOpener)
  })

  return (
    <div className="calculator-chart__single-chart-row">
      <div className="calculator-chart__single-cell is-wide is-space-unset">
        <SingleChart
          bankName={bankData.bank_display_name}
          offerPercentage={bankData.offer_percent}
          banksData={banksData}
          isFirstRow={isFirstRow}
        />
      </div>
      <div className="calculator-chart__single-cell">
        <div className="calculator-chart__row-value visible-on-mobile">{i18n('calculator.rateText')}</div>
        <div className="calculator-chart__row-value is-bold">
          {formatNumber(bankData.rate, getRatePrecision(currencyPair))}
        </div>
      </div>
      <div className="calculator-chart__single-cell">
        <div className="calculator-chart__row-value visible-on-mobile">{i18n('calculator.amountText')}</div>
        <div className={`calculator-chart__row-value ${addWtClass} is-bold`}>
          {formatNumber(bankData.amount, 2)} {sellCurrency}
        </div>
        {isFirstRow && (
          <span className="calculator-chart__fee-wrapper">
            <dialog
              className="calculator-chart__fee-info"
              data-dialog
              hidden
            >
              <p className="calculator-chart__fee-info-text">
                {i18n('calculator.includes')}{' '}
                {i18n('calculator.commission', { commission: formatNumber(bankData.fee_percent, 2) })}
              </p>
              <span className="calculator-chart__fee-triangle" />
            </dialog>
            <img
              src="/wp-content/themes/wt-theme/assets/images/how-it-works/question-mark.svg"
              className="help-tip-svg black-to-gray"
              alt="question-mark"
              loading="lazy"
              height="14"
              width="14"
              data-open-dialog
            />
          </span>
        )}
      </div>
    </div>
  )
}

export default SingleBankRow
