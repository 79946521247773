import { CalculatorSavings } from 'models/apps'
import { MarketEstimate, BankData } from 'models/market'

export const STATIC_EXCHANGE_RATES: BankData[] = [
  {
    bank_id: 'wt',
    amount: '0000.00',
    bank_display_name: 'Walutomat',
    fee_amount: '0.00',
    fee_percent: '0.00',
    offer_percent: '40',
    rate: '0.0000',
  },
  {
    bank_id: 'pkobp',
    amount: '0000.00',
    bank_display_name: 'PKO BP',
    fee_amount: '0.00',
    fee_percent: '0.00',
    offer_percent: '40',
    rate: '0.0000',
  },
  {
    bank_id: 'mbank',
    amount: '0000.00',
    bank_display_name: 'mBank',
    fee_amount: '0.00',
    fee_percent: '0.00',
    offer_percent: '40',
    rate: '0.0000',
  },
  {
    bank_id: 'alior',
    amount: '0000.00',
    bank_display_name: 'Alior',
    fee_amount: '0.00',
    fee_percent: '0.00',
    offer_percent: '40',
    rate: '0.0000',
  },
  {
    bank_id: 'bzwbk',
    amount: '0000.00',
    bank_display_name: 'santander',
    fee_amount: '0.00',
    fee_percent: '0.00',
    offer_percent: '40',
    rate: '0.0000',
  },
]

export const STATIC_CALCULATOR_SAVINGS_DATA: CalculatorSavings = {
  one_time_saving: '0.00',
  yearly_savings: '0.00',
}

export const STATIC_BANKS_DATA: MarketEstimate = {
  exchange_offers: STATIC_EXCHANGE_RATES,
  one_time_saving: STATIC_CALCULATOR_SAVINGS_DATA.one_time_saving,
  yearly_savings: STATIC_CALCULATOR_SAVINGS_DATA.yearly_savings,
  currency_name: 'PLN',
}

export const STATIC_CURRENCY_LIST = ['PLN', 'EUR', 'USD', 'CHF', 'GBP', 'CZK', 'DKK', 'NOK', 'SEK']
